<template>
  <en-dialog :model-value="modelValue" title="切换工序" center @close="$emit('update:model-value', false)" width="30%">
    <div class="flex justify-center">
      <en-button type="primary" @click="$emit('change', '1')">单工序</en-button>
      <en-button type="primary" @click="$emit('change', '2')">双工序</en-button>
      <en-button type="primary" @click="$emit('change', '3')">三工序</en-button>
    </div>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    change: (value: string) => typeof value === 'string'
  }
})
</script>
